<script>
import Axios from 'axios'
import GoogleLocation from './utility/google-location'
import AmazonAds from './utility/amazon-ads'
import NumberFormatter from './utility/number-formatter'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Email from './utility/email-validator'

export default {
  name: 'insurers-edit',
  data: function () {
    return {
      isCheckedClaimsEmail: true,
      isCheckedSuppsEmail: true,
      isCheckedPaymentEmail: true,
      isCheckedAssessorEmail: true,
      isCheckedNewAssessorEmail: true,
      regularEmailValidator: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      insurer: {
        insurer_id: null,
        insurer_name: '',
        insurer_location: '',
        insurer_email: '',
        insurer_supps_email: '',
        insurer_payment_email: '',
        insurer_default_email_type: '',
        insurer_email_default: '',
        insurer_supps_email_default: '',
        insurer_payment_email_default: '',
        insurer_phone: '',
        insurer_abn: '',
        rates: {
          estimate_methodology: 'not_set',
          estimate_rates_rr: null,
          estimate_rates_rs_rr_percentage: 20,
          estimate_rates_repair: null,
          estimate_rates_paint: null,
          estimate_rates_rs_paint_percentage: 20,
          estimate_rates_paint_material: 'M1',
          estimate_rates_mechanical: null,
        },
        cardColorId: null,
      },
      assessors: [],
      assessorStatus: [],
      new_assessor_name: '',
      new_assessor_email: '',
      new_assessor_mobile_phone: '',
      new_assessor_work_phone: '',
      insurerClaimManagements: [],
      showNewPaint: false,
      estimateMethodologies: [
        { key: 'custom', value: 'Custom' },
        { key: 'ntar', value: 'NTAR' },
        { key: 'ltar', value: 'LTAR' },
        { key: 'zi', value: 'Zurich' },
        { key: 'dollars', value: 'Dollars' },
      ],
      paintMaterials: [
        { key: 'not_set', value: '' },
        { key: 'S1', value: 'S1' },
        { key: 'S2', value: 'S2' },
        { key: 'M1', value: 'M1' },
        { key: 'M2', value: 'M2' },
        { key: 'M3', value: 'M3' },
        { key: 'M3PLUS', value: 'M3+' },
      ],
      paintMaterial: '',

    }

  },
  computed: {
    ...mapGetters({
          isCustomerUser: 'isCustomerUser',
          isStatusActive: 'isStatusActive',
          getterBusinessName: 'getBusinessName',
          isRsEnabled: 'isRsEnabled',
          getterInsurerCardColor: 'getInsurerCardColor',
        }
    ),
    computedEstimateMethodologies () {
      let em = this.estimateMethodologies.slice()
      if (this.isRsEnabled) {
        em.push({ key: 'rs', value: 'RS' })
      }
      return em
    },
    insurerClaimManagementOptions: function () {
      try {
        return this.insurerClaimManagements
      } catch (err) {
        console.log(err)
        return []
      }
    },
    computedInsurerClaimManagement: {
      get: function () {
        return { name: this.insurer.insurer_claim_management, $isDisabled: false }
      },
      set: function (s) {
        this.insurer.insurer_claim_management = s.name
      }
    },
    isSelectCustomEstimateMethodology () {
      return !!(this.insurer && this.insurer.rates && this.insurer.rates.estimate_methodology &&
          (this.insurer.rates.estimate_methodology === 'custom' || this.insurer.rates.estimate_methodology.key === 'custom'))
    },
    isSelectRsEstimateMethodology () {
      return !!(this.insurer?.rates?.estimate_methodology === 'rs' || this.insurer?.rates?.estimate_methodology?.key === 'rs')
    }
  },
  methods: {
    getInsurerColorStyles(option) {
      if (!option) {
        return null
      }
      let data = this.getterInsurerCardColor?.[option]
      if (data) {
        return {
          backgroundColor: data.background,
          color: data.color,
          fontWeight: 700,
          display: 'inline-block',
        }
      }
      return  null
    },
    setDefaultEmail (type, value) {
      if (type === 'claim') {
        if (this.insurer.insurer_supps_email_default) {
          this.insurer.insurer_supps_email_default = false
        }
      } else if (type === 'supp') {
        if (this.insurer.insurer_email_default) {
          this.insurer.insurer_email_default = false
        }
      }
    },
    correctValue (value) {
      if (_.toLower(value) === 'custom') {
        return 'Custom'
      } else if (_.toLower(value) === 'ntar') {
        return 'NTAR'
      } else if (_.toLower(value) === 'ltar') {
        return 'LTAR'
      } else if (_.toLower(value) === 'rs') {
        return 'RS'
      } else if (_.toLower(value) === 'zi') {
        return 'Zurich'
      } else if (_.toLower(value) === 'dollars') {
        return 'Dollars'
      }

    },
    reportValidity (e) {
      e.target.reportValidity()
    },
    onClaimManagementSelect: function (s) {
      console.log(s)
      this.insurer.insurer_claim_management = s.name
    },
    saveData: function () {
      if (!this.isCheckedClaimsEmail || !this.isCheckedPaymentEmail || !this.isCheckedSuppsEmail || !this.isCheckedAssessorEmail || !this.isCheckedNewAssessorEmail) {
        toastr.error('Your changes could not be saved, please check the form fields again')
        return
      }

      if (this.new_assessor_name || this.new_assessor_email) {
        this.addAssessor()
      }

      NProgress.start()
      let insurer_id = this.$route.params.insurer_id
      let insurer = _.cloneDeep(this.insurer)
      if (!insurer.cardColorId) {
        insurer.cardColorId = 0
      }
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/insurer/${insurer_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: JSON.stringify({ insurer: insurer, assessors: this.assessors })
      })
          // Axios.post('/fe/job', JSON.stringify({insurer: this.job.insurer, vehicle: this.job.vehicle, booking_date: this.job.booking_date, completion_date: this.job.completion_date}))
          .then(response => {
            if (response.status == 200) {
              toastr.success('insurer details saved')
              // this.$router.push('/insurers')
            } else {
              toastr.error(response.data.msg)
            }
            NProgress.done()
            this.loadInsurer()
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
    },
    loadInsurer: function () {
      NProgress.start()
      // self = this
      let insurer_id = this.$route.params.insurer_id
      Axios.get(`/fe/insurer/${insurer_id}`)
          .then(response => {
            this.insurer = response.data.insurer
            let value = this.insurer.rates.estimate_methodology
            this.insurer.rates.estimate_methodology = {
              key: _.toLower(value),
              value: this.correctValue(value),
            }
            if (value && value.toLowerCase() === 'custom') {
              this.showNewPaint = true
            }
            this.assessors = response.data.assessors
            this.insurerClaimManagements = response.data.insurer_claim_managements
            NProgress.done()
          })
          .catch(error => {
            toastr.error('insurer not found')
            console.log(error)
            NProgress.done()
            this.$router.push('/insurers')
          })
    },
    addAssessor: function () {
      if (!this.isCheckedNewAssessorEmail || !this.isStatusActive) {
        return
      }
      if (!this.new_assessor_name || !this.new_assessor_email) {
        return
      }

      this.assessors.push({
        insurer_assessor_id: null,
        insurer_id: this.insurer.insurer_id,
        vendor_id: this.insurer.vendor_id,
        assessor_name: this.new_assessor_name,
        assessor_email: this.new_assessor_email,
        assessor_mobile_phone: this.new_assessor_mobile_phone,
        assessor_work_phone: this.new_assessor_work_phone,
        deleted: false
      })

      this.new_assessor_name = ''
      this.new_assessor_email = ''
      this.new_assessor_mobile_phone = ''
      this.new_assessor_work_phone = ''
    },
    toggleDeleteAssessor: function (index) {
      if (this.isCustomerUser || !this.isStatusActive) {
        return
      }
      this.assessors[index]['deleted'] = !this.assessors[index]['deleted']
      console.log(this.assessors)
    },
    addressChanged: function (addr) {
      // this.insurer.insurer_location = addr.address
    },
    selectedEstimateMethodology (value) {
      if (value && value.toLowerCase() === 'custom') {
        this.showNewPaint = true
      } else {
        this.showNewPaint = false
      }
    },
    selectedPaintMaterial (value) {
      console.log(value)

    }
  },

  mounted: function () {
    this.loadInsurer()
  },
  components: {
    GoogleLocation,
    AmazonAds,
    NumberFormatter,
    Multiselect,
    Email,
  },
}
</script>
<template>
  <div class="insurers-edit-page">
    <div class="page-header">
      <h4>Edit Insurer: {{ insurer.insurer_name }}</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/insurers">View Insurers</router-link>
        </li>
        <li class="breadcrumb-item active">Edit Insurer</li>
      </ol>
      <h4 class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <tabs>
      <tab name="Details" id="details">
        <div class="box box-block bg-white">
          <div class="box-block main-card-block">
            <div class="row">
              <div class="col-md-5 child-card-block">
                <div class="card">
                  <div class="card-header bg-navyblue">
                    Insurer Details
                  </div>
                  <div class="card-block bg-white">
                    <div class="form-group row">
                      <label for="insurer_name" class="col-lg-5 col-form-label">Insurer</label>
                      <div class="col-lg-7">
                        <input :readonly="!isStatusActive" type="text"
                               v-model="insurer.insurer_name"
                               class="form-control" id="insurer_name" placeholder="Insurer Name">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="insurer_abn" class="col-lg-5 col-form-label">Insurer ABN</label>
                      <div class="col-lg-7">
                        <input :readonly="!isStatusActive" type="text"
                               v-model="insurer.insurer_abn"
                               class="form-control" id="insurer_abn" placeholder="Insurer ABN">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="insurer_name" class="col-lg-5 col-form-label">Phone Nbr</label>
                      <div class="col-lg-7">
                        <input :readonly="!isStatusActive" type="tel"
                               pattern="\+?[0-9- ()]{3,}" @keyup="reportValidity" v-model="insurer.insurer_phone"
                               class="form-control"
                               id="insurer_phone"
                               placeholder="Insurer Phone Nbr">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="insurer_location" class="col-lg-5 col-form-label">Location</label>
                      <div class="col-lg-7">
                        <google-location :isDisabled="!isStatusActive"
                                         v-model="insurer.insurer_location"
                                         class="col-lg-7 form-control" id="insurer_location"
                                         placeholder="Insurer Location"
                                         @onPlaceChange="addressChanged"></google-location>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Insurer File Colour</label>
                      <div class="col-lg-7">
                        <multiselect
                            v-model="insurer.cardColorId"
                            class="insurer-card-colour"
                            :options="Object.keys(getterInsurerCardColor)"
                            :showLabels="false"
                            style="opacity: 1; height: 32px;"
                            :option-height="32"
                            :max-height="255"
                            :searchable="false"
                            :close-on-select="true">
                          <template #singleLabel="{ option }">
                            <div :style="getInsurerColorStyles(option)"
                            style="padding: .3rem .75rem; border-radius: 3px; transform: translateX(-10px); "
                            >{{insurer.insurer_name}}</div>
                          </template>
                          <template #option="{ option }">
                            <div :style="getInsurerColorStyles(option)"
                                 style="padding: .3rem .75rem; border-radius: 3px; transform: translateX(-10px); ">{{insurer.insurer_name}}</div>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Claims Email</label>
                      <email
                          :email="insurer.insurer_email"
                          :is-disabled="!isStatusActive"
                          @onChange="(value) => insurer.insurer_email = value"
                          @checked="(value) => isCheckedClaimsEmail = value"
                          :placeholderText="'Claims Email'"
                          class="col-lg-7"
                      ></email>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Set as default for Estimates</label>
                      <div class="col-lg-7">
                        <label class="form-check-inline checkbox">
                          <input :disabled="!isStatusActive"
                                 @change="setDefaultEmail('claim', insurer.insurer_email_default)"
                                 v-model="insurer.insurer_email_default" class="form-check-input"
                                 name="default-claim-checkbox" type="checkbox">
                          <span class="icon"><i class='bx bx-check'></i></span><span
                            class="text"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Supps Email</label>
                      <email
                          :email="insurer.insurer_supps_email"
                          :is-disabled="!isStatusActive"
                          @onChange="(value) => insurer.insurer_supps_email = value"
                          @checked="(value) => isCheckedSuppsEmail = value"
                          :placeholderText="'Supps Email'"
                          class="col-lg-7"
                      ></email>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Set as default for Estimates</label>
                      <div class="col-lg-7">
                        <label class="form-check-inline checkbox">
                          <input :disabled="!isStatusActive"
                                 @change="setDefaultEmail('supp', insurer.insurer_supps_email_default)"
                                 v-model="insurer.insurer_supps_email_default" class="form-check-input"
                                 name="default-supp-checkbox" type="checkbox">
                          <span class="icon"><i class='bx bx-check'></i></span><span
                            class="text"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Payment Email</label>
                      <email
                          :email="insurer.insurer_payment_email"
                          :is-disabled="!isStatusActive"
                          @onChange="(value) => insurer.insurer_payment_email = value"
                          @checked="(value) => isCheckedPaymentEmail = value"
                          :placeholderText="'Payment Email'"
                          class="col-lg-7"
                      ></email>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Set as default for Invoices</label>
                      <div class="col-lg-7">
                        <label class="form-check-inline checkbox">
                          <input :disabled="!isStatusActive" :readonly="!isStatusActive"
                                 v-model="insurer.insurer_payment_email_default" class="form-check-input"
                                 name="default-pay-checkbox" type="checkbox">
                          <span class="icon"><i class='bx bx-check'></i></span><span
                            class="text"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-5 col-form-label">Claims Management System</label>
                      <div class="col-lg-7">
                        <multiselect
                            ref="claimManagementSystem"
                            v-model="computedInsurerClaimManagement"
                            :options="insurerClaimManagementOptions"
                            track-by="name"
                            label="name"
                            :showLabels="false"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            @select="onClaimManagementSelect"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header bg-navyblue">
                    Rates
                  </div>
                  <div class="card-block bg-white card-block-rates">
                    <div class="form-group row">
                      <label for="estimate_methodology" class="col-sm-3 col-lg-5 col-form-label">Estimate
                        Methodology</label>
                      <div class="col-sm-9 col-lg-5 col-form-item" style="min-width: 167px">
                        <multiselect
                            id="estimate_methodology"
                            v-model="insurer.rates.estimate_methodology"
                            :options="computedEstimateMethodologies"
                            :showLabels="false"
                            :disabled="!isStatusActive"
                            style="opacity: 1"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select State"
                            track-by="key"
                            label="value"
                            @select="selectedEstimateMethodology($event.value)"
                        >
                        </multiselect>
                      </div>

                    </div>

                    <div class="form-group row">
                      <label for="rr" class="col-sm-3 col-lg-5 col-form-label">RR</label>
                      <div class="col-sm-9 col-lg-5 col-form-item">
                        <!-- <input type="text" v-model="estimate.rates.rr" class="form-control" id="rr" placeholder="RR Amount"> -->
                        <input v-if="insurer.rates && insurer.rates.estimate_methodology && insurer.rates.estimate_methodology.key == 'dollars'" :value="'$0.00'" readonly class="form-control">
                        <number-formatter  v-else :isDisabled="!isStatusActive" ref="rr"
                                          v-model="insurer.rates.estimate_rates_rr" type="text" class="form-control"
                                          placeholder="RR Amount"
                                          format="$0,0.00"></number-formatter>
                      </div>
                      <div class="col-sm-9 col-lg-2  col-form-item-percentage"
                           style="padding-left: 0; padding-right: 0">
                        <number-formatter v-if="isSelectRsEstimateMethodology"
                                          :isDisabled="!isStatusActive"
                                          v-model="insurer.rates.estimate_rates_rs_rr_percentage"
                                          :percentage="true"
                                          format="0%"
                                          type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="repair" class="col-sm-3 col-lg-5 col-form-label">Repair</label>
                      <div class="col-sm-9 col-lg-5 col-form-item">
                        <input v-if="insurer.rates && insurer.rates.estimate_methodology && insurer.rates.estimate_methodology.key == 'dollars'" :value="'$0.00'" readonly class="form-control">
                        <number-formatter v-else :isDisabled="!isStatusActive" ref="repair" type="text"
                                          v-model="insurer.rates.estimate_rates_repair" class="form-control"
                                          placeholder="Repair"
                                          format="$0,0.00"></number-formatter>
                      </div>
                    </div>
                    <template v-if="!isSelectCustomEstimateMethodology">
                      <div class="form-group row">
                        <label for="paint" class="col-sm-3 col-lg-5 col-form-label">Paint</label>
                        <div class="col-sm-9 col-lg-5 col-form-item">
                          <multiselect
                              v-if="showNewPaint"
                              v-model="insurer.rates.estimate_rates_paint_material"
                              ref="RatesPaintMaterial"
                              id="paint_material"
                              placeholder="Select Material"
                              :options="paintMaterials"
                              :disabled="!isStatusActive"
                              :showLabels="false"
                              :option-height="29"
                              :max-height="203"
                              :close-on-select="true"
                              track-by="key"
                              label="value"
                              @select="selectedPaintMaterial($event.value)"
                          ></multiselect>

                          <template v-else>
                            <input v-if="insurer.rates && insurer.rates.estimate_methodology && insurer.rates.estimate_methodology.key == 'dollars'" :value="'$0.00'" readonly class="form-control">
                            <number-formatter v-else :isDisabled="!isStatusActive" ref="paint"
                                              type="text" v-model="insurer.rates.estimate_rates_paint"
                                              class="form-control" placeholder="Paint"
                                              format="$0,0.00"></number-formatter>
                          </template>
                        </div>
                        <div class="col-sm-9 col-lg-2 col-form-item-percentage"
                             style="padding-left: 0; padding-right: 0">
                          <number-formatter  v-if="isSelectRsEstimateMethodology"
                                 v-model="insurer.rates.estimate_rates_rs_paint_percentage"
                                             :isDisabled="!isStatusActive"
                                             :percentage="true"
                                             format="0%"
                                 type="text" class="form-control" />
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="form-group row">
                        <label for="repair" class="col-sm-3 col-lg-5 col-form-label">S1</label>
                        <div class="col-sm-9 col-lg-5 col-form-item">
                          <number-formatter ref="repair" type="text" :isDisabled="!isStatusActive"
                                            v-model="insurer.custom_paint_times.s1" class="form-control"
                                            placeholder="S1"
                                            format="$0,0.00"></number-formatter>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="repair" class="col-sm-3 col-lg-5 col-form-label">S2</label>
                        <div class="col-sm-9 col-lg-5 col-form-item">
                          <number-formatter ref="repair" type="text" :isDisabled="!isStatusActive"
                                            v-model="insurer.custom_paint_times.s2" class="form-control"
                                            placeholder="S1"
                                            format="$0,0.00"></number-formatter>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="repair" class="col-sm-3 col-lg-5 col-form-label">M1</label>
                        <div class="col-sm-9 col-lg-5 col-form-item">
                          <number-formatter ref="repair" type="text" :isDisabled="!isStatusActive"
                                            v-model="insurer.custom_paint_times.m1" class="form-control"
                                            placeholder="S1"
                                            format="$0,0.00"></number-formatter>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="repair" class="col-sm-3 col-lg-5 col-form-label">M2</label>
                        <div class="col-sm-9 col-lg-5 col-form-item">
                          <number-formatter ref="repair" type="text" :isDisabled="!isStatusActive"
                                            v-model="insurer.custom_paint_times.m2" class="form-control"
                                            placeholder="S1"
                                            format="$0,0.00"></number-formatter>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="repair" class="col-sm-3 col-lg-5 col-form-label">M3</label>
                        <div class="col-sm-9 col-lg-5 col-form-item">
                          <number-formatter ref="repair" type="text" :isDisabled="!isStatusActive"
                                            v-model="insurer.custom_paint_times.m3" class="form-control"
                                            placeholder="S1"
                                            format="$0,0.00"></number-formatter>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="repair" class="col-sm-3 col-lg-5 col-form-label">M3+</label>
                        <div class="col-sm-9 col-lg-5 col-form-item">
                          <number-formatter ref="repair" type="text" :isDisabled="!isStatusActive"
                                            v-model="insurer.custom_paint_times.m3plus" class="form-control"
                                            placeholder="S1"
                                            format="$0,0.00"></number-formatter>
                        </div>
                      </div>
                    </template>
                    <div class="form-group row">
                      <label for="repair" class="col-sm-3 col-lg-5 col-form-label">Mechanical</label>
                      <div class="col-sm-9 col-lg-5 col-form-item">
                        <input v-if="insurer.rates && insurer.rates.estimate_methodology && insurer.rates.estimate_methodology.key == 'dollars'" :value="'$0.00'" readonly class="form-control">
                        <number-formatter v-else type="text" class="form-control" placeholder="Mechanical"
                                          v-model="insurer.rates.estimate_rates_mechanical"
                                          :isDisabled="!isStatusActive"
                                          format="$0,0.00"></number-formatter>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 child-card-block">
                <div class="card">
                  <div class="card-header bg-navyblue">
                    Assessors Info
                  </div>
                  <div class="card-block assessors bg-white">
                    <div class="d-flex w-100">
                      <label class="assessors-info-title col-form-label text-center">Name</label>
                      <label class="assessors-info-title col-form-label text-center">Email</label>
                      <label class="assessors-info-title col-form-label text-center">Mobile Phone</label>
                      <label class="assessors-info-title col-form-label text-center">Work Phone</label>
                      <label class="col-form-label" style="width: 40px !important"> </label>
                    </div>
                    <div v-for="(a, index) in assessors" class="row mb-0-5" :class="{'deleted': a['deleted'] }">
                      <div class="form-group d-flex">
                        <div class="assessors-info-inputs">
                          <input type="text" v-model="a.assessor_name" class="form-control"/>
                        </div>
                        <div class="assessors-info-inputs">
                          <email
                              :email="a.assessor_email"
                              :is-disabled="!isStatusActive"
                              @onChange="(value) => a.assessor_email = value"
                              @checked="(value) => isCheckedAssessorEmail = value"
                              :placeholderText="''"

                          ></email>
                        </div>
                        <div class="assessors-info-inputs">
                          <input type="text" v-model="a.assessor_mobile_phone" class="form-control"/>
                        </div>
                        <div class="assessors-info-inputs" style="padding-right:0px !important;">
                          <input type="text" v-model="a.assessor_work_phone" class="form-control"/>
                        </div>
                        <div class="text-center" style="width: 40px !important">
                                                    <span @click="toggleDeleteAssessor(index)"
                                                          :class="{'bx bx-minus-circle text-danger': !a['deleted'], 'fa-undo text-success': a['deleted'],}"
                                                          class="fa py-0-5 clickable"></span>
                        </div>
                      </div>
                    </div>
                    <div class="mb-0-5">
                      <div class="form-group d-flex w-100">
                        <div class="assessors-info-inputs">
                          <input type="text" v-model="new_assessor_name" class="form-control"/>
                        </div>
                        <div class="assessors-info-inputs">
                          <email
                              :email="new_assessor_email"
                              :is-disabled="!isStatusActive"
                              @onChange="(value) => new_assessor_email = value"
                              @checked="(value) => isCheckedNewAssessorEmail = value"
                              :placeholderText="''"
                          ></email>
                        </div>
                        <div class="assessors-info-inputs">
                          <input type="text" v-model="new_assessor_mobile_phone" class="form-control"/>
                        </div>
                        <div class="assessors-info-inputs" style="padding-right:0px !important;">
                          <input type="text" v-model="new_assessor_work_phone" class="form-control"/>
                        </div>
                        <div class="text-center" style="width: 40px !important">
                          <span @click="addAssessor" class="bx bx-plus-circle py-0-5 text-success clickable"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--                                    <amazon-ads></amazon-ads>-->
              </div>
            </div>
          </div>

        </div>
      </tab>
      <template v-if="isStatusActive" slot="nav-item-right">
        <button @click="saveData" type="button"
                class="btn btn-primary w-min-sm waves-effect waves-light additional-btn-class btn-save-hover">Save
        </button>
      </template>
    </tabs>

  </div>
</template>

<style>
.insurers-edit-page .deleted input.form-control {
  text-decoration: line-through;
  border-color: transparent;
  color: #AAAAAA;
  background-color: transparent;
}

.insurers-edit-page .tab-content {
  padding: 10px 0;
}

.insurers-edit-page .card-header {
  font-family: "Nunito Sans";
  font-size: 13px;
  font-weight: bold;
}

.insurers-edit-page .child-card-block:last-child {
  padding-left: 0;
}

.assessors-info-inputs,
.assessors-info-title {
  width: 25% !important;
  margin-right: 10px !important;
}

@media screen and (max-width: 991px) {
  .child-card-block {
    width: 100% !important;
  }
}

.insurers-edit-page .card-block {
  padding-left: 0;
}

.insurers-edit-page .assessors {
  padding: 0 0 0 10px;
}

.insurers-edit-page .assessors .form-group {
  margin-bottom: 2px !important;
}

.insurers-edit-page .assessors .bx {
  font-size: 20px;
}

.insurers-edit-page #insurer_location {
  padding: 0;
  border: 0;
}

.insurers-edit-page #insurer_location input {
  border-radius: 3px;
}

.insurers-edit-page .additional-btn-class {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>

<style scoped>
.btn.btn-primary.btn-save-hover{
  transition: none;
  background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
}
.btn.btn-primary.btn-save-hover:hover{
  background: #5E78FF;
}
.insurer-card-colour >>> .multiselect__tags .multiselect__single{
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
}
.insurer-card-colour >>> .multiselect__content-wrapper{
  padding-bottom: 7px;
}
.insurer-card-colour >>> .multiselect__option--selected{
  color: inherit;
}
.insurer-card-colour >>> .multiselect__option--highlight:after{
  display: none !important;
}
.insurer-card-colour >>> .multiselect__option--highlight{
  background-color: inherit !important;
  font-weight: 800;
  color: inherit;
  padding: .3rem .75rem;
  margin-top: .2rem;
  margin-bottom: .2rem;
  border-radius: 3px;
}
.insurer-card-colour >>> .multiselect__element .multiselect__option{
  padding: .3rem .75rem;
  margin-top: .4rem;
  margin-bottom: .4rem;
  border-radius: 3px;
  height: 20px;
}
.insurer-card-colour >>> .multiselect__element{
  font-weight: 700;
  display: flex;
  padding: 0 5px;
}

@media screen and (max-width: 1065px) {
  .card-block-rates .col-form-label:first-child {
    width: 30% !important;
  }
}

@media screen and (max-width: 992px) {
  .card-block-rates .col-form-item {
    width: 40% !important;
  }

  .card-block-rates .col-form-item-percentage {
    width: 18% !important;
  }

  .card-block-rates .col-form-label {
    width: 40% !important;
  }
}

@media screen and (max-width: 580px) {
  .card-block-rates .col-form-item {
    width: 100% !important;
  }

  .card-block-rates .col-form-item-percentage {
    width: 100% !important;
    padding-top: 10px;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .card-block-rates .col-form-label {
    width: 100% !important;
  }
}
</style>
