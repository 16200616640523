<script>
    import Axios from 'axios';
    import GoogleLocation from './utility/google-location';
    import AmazonAds from './utility/amazon-ads';
    import {mapGetters} from "vuex";
    import BlockPagination from './utility/block-pagination';
    import Multiselect from "vue-multiselect";
    import _ from "lodash";
    import Email from './utility/email-validator';

    export default {
        name: 'customers-edit',
        data: function () {
            return {
                isCheckedEmail: true,
                optionsForState: [
                    {value: 'Select your state', key: '',},
                    {value: 'Queensland', key: 'QLD',},
                    {value: 'Northern Territory', key: 'NT',},
                    {value: 'New South Wales', key: 'NSW',},
                    {value: 'Victoria', key: 'VIC',},
                    {value: 'Tasmania', key: 'TAS',},
                    {value: 'South Australia', key: 'SA',},
                    {value: 'Western Australia', key: 'WA',},
                ],
                optionsForCountry: [
                    {value: 'Select your country', key: '',},
                    {value: 'Australia', key: 'AU',},
                    {value: 'New Zealand', key: 'NZ',},
                ],
                customer: {
                    id: 0,
                    name: '',
                    address: '',
                    level_unit_lot: '',
                    street_name: '',
                    suburb: '',
                    state: '',
                    postcode: '',
                    country: '',
                    mobile: '',
                    phone: '',
                    fax: '',
                    business_fax: '',
                    business_email: '',
                    email: '',
                    status: true,
                    abn: '',
                    business_mobile: '',
                    accountStatus: {
                        theyOwe: 0,
                        overdue: 0,
                        revenueCurrent: 0,
                        revenuePast: 0,
                    },
                    vehicles: [],
                    type: null,
                },
                fieldsForIndividual: [
                    {label: 'Rego', key: 'rego_number', sortable: true, tdClass: 'text-center clickable number', thClass: 'text-center bg-navyblue number'},
                    {label: 'Cntct Nbr 1', key: 'contact_number_1', sortable: true, tdClass: 'text-center clickable', thClass: 'text-center bg-navyblue'},
                    {label: 'Email', key: 'contact_email', sortable: true, tdClass: 'text-center clickable', thClass: 'text-center bg-navyblue'},
                    {label: 'Make', key: 'make', sortable: true, tdClass: 'text-center clickable card-number', thClass: 'text-center bg-navyblue card-number'},
                    {label: 'Model', key: 'model', sortable: true, tdClass: 'clickable customer', thClass: 'text-center bg-navyblue customer'},
                    {label: 'Build Date', key: 'dom', sortable: true, tdClass: 'text-center clickable', thClass: 'text-center bg-navyblue'},
                    {label: '', key: 'action', sortable: false, tdClass: 'text-center clickable colour', thClass: 'text-center bg-navyblue colour'},
                ],
                fieldsForFleetAndBusiness: [
                    {label: 'Rego', key: 'rego_number', sortable: true, tdClass: 'text-center clickable number', thClass: 'text-center bg-navyblue number'},
                    {label: 'Driver Name', key: 'last_driver_name', sortable: true, tdClass: 'text-center clickable driver-name', thClass: 'text-center bg-navyblue driver-name'},
                    {label: 'Cntct Nbr 1', key: 'last_contact_number_1', sortable: true, tdClass: 'text-center clickable', thClass: 'text-center bg-navyblue'},
                    {label: 'Cntct Nbr 2', key: 'last_contact_number_2', sortable: true, tdClass: 'text-center clickable', thClass: 'text-center bg-navyblue'},
                    {label: 'Email', key: 'last_contact_email', sortable: true, tdClass: 'text-center clickable', thClass: 'text-center bg-navyblue'},
                    {label: 'Make', key: 'make', sortable: true, tdClass: 'text-center clickable card-number', thClass: 'text-center bg-navyblue card-number'},
                    {label: 'Model', key: 'model', sortable: true, tdClass: 'clickable customer', thClass: 'text-center bg-navyblue customer'},
                    {label: 'Build Date', key: 'dom', sortable: true, tdClass: 'text-center clickable', thClass: 'text-center bg-navyblue'},
                    {label: '', key: 'action', sortable: false, tdClass: 'text-center clickable colour', thClass: 'text-center bg-navyblue colour'},
                ],
                carsPerPage: 10,
                carsCurrentPage: 1
            };
        },
        computed: {
          ...mapGetters({
              'isCustomerUser': 'isCustomerUser',
              isStatusActive: 'isStatusActive',
              getterBusinessName : 'getBusinessName',
            }),
            isOwnerTypeBusinessFleet() {
                return !!(this.customer.type == 'F');
            },
            isOwnerTypeBusiness() {
                return !!(this.customer.type == 'B');
            },
            isOwnerTypeIndividual() {
                return !!(this.customer.type == 'I');
            },
            carsFields() {
                if (this.isOwnerTypeIndividual) {
                    return this.fieldsForIndividual;
                }
                return this.fieldsForFleetAndBusiness;
            },
            totalCars() {
                return this.customer.vehicles.length;
            },
            fromCars() {
                if (this.totalCars === 0) {
                    return 0;
                }
                return (this.carsCurrentPage - 1) * this.carsPerPage + 1;
            },
            toCars() {
                const toCars = (this.carsCurrentPage - 1) * this.carsPerPage + this.carsPerPage;
                if (toCars > this.totalCars) return this.totalCars;
                return toCars;
            },
            computedState: {
                get: function () {
                    let vm = this;
                    try {
                    let item = _.find(this.optionsForState, function (itm) {
                        return itm.key == vm.customer.state;
                    });
                    return item;
                    } catch (e) {
                    return {};
                    }
                },
                set: function (item) {
                    if (item) {
                    this.customer.state = item.key;
                    }
                },
            },
            computedCountry: {
                get: function () {
                    let vm = this;
                    try {
                    let item = _.find(this.optionsForCountry, function (itm) {
                        return itm.key == vm.customer.country;
                    });
                    return item;
                    } catch (e) {
                    return {};
                    }
                },
                set: function (item) {
                    if (item) {
                    this.customer.country = item.key;
                    }
                },
            },
        },
        methods: {
            deleteCustomer() {
                if (!confirm("Are you sure?")) {
                    return;
                }
                NProgress.start();
                let customer_id = this.$route.params.customer_id;
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/customer/${customer_id}/delete`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify(this.customer)
                }).then(response => {
                    if (response.status === 200) {
                        toastr.success('Customer deleted');
                        this.$store.commit('removeCustomerVehicles', customer_id)
                        this.$router.push('/customers');
                    } else {
                        toastr.error(response.data.msg);
                    }
                    NProgress.done();
                })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            deleteCar(id) {
                if (!this.isStatusActive) {
                  return;
                }
                if (!confirm("Are you sure?")) {
                    return;
                }
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/vehicle/${id}/delete`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify(this.customer)
                }).then(response => {
                    if (response.status === 200) {
                        toastr.success('Car deleted');
                        this.loadCustomer();
                    } else {
                        toastr.error(response.data.msg);
                    }
                    NProgress.done();
                })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            saveData: function () {
                if (!this.isCheckedEmail) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                NProgress.start();
                let customer_id = this.$route.params.customer_id;
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/customer/${customer_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: this.customer
                })
                // Axios.post('/fe/job', JSON.stringify({customer: this.job.customer, vehicle: this.job.vehicle, booking_date: this.job.booking_date, completion_date: this.job.completion_date}))
                    .then(response => {
                        if (response.status == 200) {
                            toastr.success('Customer details saved');
                            // this.$router.push('/customers')
                        } else {
                            toastr.error(response.data.msg);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            loadCustomer: function () {
                NProgress.start();
                let customer_id = this.$route.params.customer_id;
                Axios.get(`/fe/customer/${customer_id}`)
                    .then(response => {
                        this.customer = response.data;
                        console.log('customer', response.data);
                        NProgress.done();
                    })
                    .catch(error => {
                        toastr.error("Customer not found");
                        console.log(error);
                        NProgress.done();
                        this.$router.push('/customers');
                    });
            },
            addressChanged: function (addr) {
                this.customer.address = addr["address"];
                this.customer.level_unit_lot = addr['levelUnitLot'];
                this.customer.street_name = addr["street_name"];
                this.customer.suburb = addr["suburb"];
                this.customer.state = addr["state"];
                this.customer.postcode = addr["postcode"];
                this.customer.country = addr["country"];
            }
        },
        mounted: function () {
            this.loadCustomer();
        },
        components: {
            GoogleLocation,
            AmazonAds,
            Multiselect,
            Email,
            BlockPagination,
        }
    };
</script>

<template>
    <div class="edit-customer">
        <div class="page-header">
            <h4>Edit Customer: {{customer.name}}</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/customers">View Customers</router-link>
                </li>
                <li class="breadcrumb-item active">Edit Customer</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div class="box-block bg-white">
            <!--<p class="font-90 text-muted mb-1">Edit Customer details</p>-->
            <div class="box-block bg-white  save-button-header">
                <tabs :options="{useUrlFragment: false}">
                    <tab name="Details" id="details">
                        <div class="card main-card">
                            <div class="box-block main-card-block">
                                <div class="row">
                                    <div class="col-md-6 pl-0">
                                        <div class="card">
                                            <div class="card-header bg-navyblue">
                                                Customer Details
                                            </div>
                                            <div class="card-block bg-white">

                                                <div class="form-group row">
                                                    <label for="customer_status" class="col-lg-3 col-form-label">Status</label>
                                                    <div class="col-lg-9">
                                                        <div class="d-flex">
                                                            <toggle-button v-model="customer.isActive"
                                                                           id="customer_status"
                                                                           class="mb-0 rs-toggle__button"
                                                                           :disabled="!isStatusActive"
                                                                           color="#5E79FF"
                                                                           :sync="true"
                                                                           :width="40"
                                                                           :labels="false"/>
                                                            <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                                              <template v-if="customer.isActive">Active</template>
                                                              <template v-else>Not Active</template>
                                                          </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row" >
                                                    <label for="owner_type" class="col-lg-3 col-form-label">Owner Type</label>
                                                    <div class="col-lg-9" id="owner_type">
                                                        <label class="form-check-inline radio" style="margin-top: 5px">

                                                            <input v-model="customer.type"
                                                                   :disabled="!isStatusActive"
                                                                   class="form-check-input" type="radio" name="customer_type"
                                                                   id="individual_customer_type" value="I">
                                                            <span class="icon"><i class='bx bx-check'></i></span><span
                                                                class="text">Individual</span>

                                                        </label>
                                                        <label class="form-check-inline radio" style="margin-top: 5px">
                                                            <input v-model="customer.type"
                                                                   class="form-check-input" type="radio" name="customer_type"
                                                                   :disabled="!isStatusActive"
                                                                   id="business_customer_type" value="B">
                                                            <span class="icon"><i class='bx bx-check'></i></span><span
                                                                class="text">Business</span>
                                                        </label>

                                                        <label class="form-check-inline radio" style="margin-top: 5px">
                                                            <input v-model="customer.type"
                                                                   class="form-check-input" type="radio" name="customer_type"
                                                                   :disabled="!isStatusActive"
                                                                   id="business_fleet_customer_type" value="F">
                                                            <span class="icon"><i class='bx bx-check'></i></span><span style="min-width: 121px"
                                                                                                                       class="text">Business Fleet</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="customer_name" class="col-lg-3 col-form-label">Name</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" :readonly="!isStatusActive"  v-model="customer.name"  class="form-control" id="customer_name" placeholder="Customer Name">
                                                    </div>
                                                </div>

                                                <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
                                                    <label for="customer_abn" class="col-lg-3 col-form-label">Business ABN</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" :readonly="!isStatusActive"  v-model="customer.abn"  class="form-control" id="customer_abn" placeholder="Business ABN">
                                                    </div>
                                                </div>

                                                <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
                                                    <label for="customer_business_contact_nbr" class="col-lg-3 col-form-label">Business Contact Nbr</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" :readonly="!isStatusActive" v-model="customer.mobile"  class="form-control" id="customer_business_contact_nbr" placeholder="Business Contact Nbr">
                                                    </div>
                                                </div>

                                                <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
                                                    <label for="customer_fax" class="col-lg-3 col-form-label">Business Fax</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" :readonly="!isStatusActive"  v-model="customer.fax"  class="form-control" id="customer_fax" placeholder="Business Fax">
                                                    </div>
                                                </div>

                                                <div class="form-group row" v-if="isOwnerTypeBusiness || isOwnerTypeBusinessFleet">
                                                    <label  class="col-lg-3 col-form-label">Business Email</label>

                                                    <email
                                                        :email="customer.email"
                                                        :is-disabled="!isStatusActive"
                                                        @onChange="(value) => customer.email = value"
                                                        @checked="(value) => isCheckedEmail = value"
                                                        class="col-lg-9"
                                                        :placeholder-text="'Business Email'"
                                                    ></email>
                                                </div>

                                                <div class="form-group row" v-if="isOwnerTypeIndividual">
                                                    <label for="mobile" class="col-lg-3 col-form-label">Contact Nbr 1</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" :readonly="!isStatusActive" v-model="customer.mobile"  class="form-control" id="mobile" placeholder="Mobile">
                                                    </div>
                                                </div>
                                                <div class="form-group row" v-if="isOwnerTypeIndividual">
                                                    <label for="fax" class="col-lg-3 col-form-label">Fax</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" :readonly="!isStatusActive" v-model="customer.fax"  class="form-control" id="fax" placeholder="Fax">
                                                    </div>
                                                </div>

                                                <div class="form-group row" v-if="isOwnerTypeIndividual">
                                                    <p style="color: rgba(0, 0, 0, 0.5); padding-left: 15px; font-weight: bold">Home address</p>
                                                </div>

                                                <div class="form-group row" v-else>
                                                    <p style="color: rgba(0, 0, 0, 0.5); padding-left: 15px; font-weight: bold">Business address</p>
                                                </div>

                                                <div class="form-group row" v-if="isOwnerTypeIndividual">
                                                    <label class="col-lg-3 col-form-label">Email</label>
                                                    <email
                                                        :email="customer.email"
                                                        :is-disabled="!isStatusActive"
                                                        @onChange="(value) => customer.email = value"
                                                        @checked="(value) => isCheckedEmail = value"
                                                        class="col-lg-9"
                                                    ></email>
                                                </div>
                                                <!--												<div class="form-group row">-->
                                                <!--													<label for="address" class="col-lg-3 col-form-label">Search for an Address</label>-->
                                                <!--													<div class="col-lg-9">-->
                                                <!--														<google-location type="text" v-model="customer.address" class="form-control" id="address" placeholder="Search for an Address" @onPlaceChange="addressChanged"></google-location>-->
                                                <!--													</div>-->
                                                <!--												</div>-->
                                                <div class="form-group row">
                                                    <label for="level_unit_lot" class="col-sm-3 col-form-label">Level/Unit/Lot Nbr</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" v-model="customer.level_unit_lot" :readonly="!isStatusActive"  class="form-control" id="level_unit_lot" placeholder="Lot, Unit, or Level number">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="street_name" class="col-lg-3 col-form-label">Street Name</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" v-model="customer.street_name" :readonly="!isStatusActive"  class="form-control" id="street_name" placeholder="Street Name">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="suburb" class="col-lg-3 col-form-label">Suburb</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" v-model="customer.suburb"  :readonly="!isStatusActive" class="form-control" id="suburb" placeholder="Suburb">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="state" class="col-lg-3 col-form-label">State</label>
                                                    <div class="col-lg-9">
                                                        <multiselect
                                                            v-model="computedState"
                                                            :options="optionsForState"
                                                            :showLabels="false"
                                                            :option-height="29"
                                                            :max-height="203"
                                                            :close-on-select="true"
                                                            :disabled="!isStatusActive"
                                                            style="opacity: 1"
                                                            track-by="key"
                                                            label="value"
                                                        ></multiselect>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="postcode"  class="col-lg-3 col-form-label">Postcode</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" v-model="customer.postcode" :readonly="!isStatusActive"  class="form-control" id="postcode" placeholder="Postcode">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="country" class="col-lg-3 col-form-label">Country</label>
                                                    <div class="col-lg-9">
                                                        <multiselect
                                                            v-model="computedCountry"
                                                            :options="optionsForCountry"
                                                            :showLabels="false"
                                                            :option-height="29"
                                                            :disabled="!isStatusActive"
                                                            style="opacity: 1"
                                                            :max-height="203"
                                                            :close-on-select="true"
                                                            track-by="key"
                                                            label="value"
                                                        ></multiselect>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-12">
                                                        <button
                                                                v-if="!isCustomerUser && isStatusActive"
                                                                @click="deleteCustomer"
                                                                class="btn btn-danger float-right font-weight-bold">Delete
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 pr-0">
                                        <div class="card-header bg-navyblue">
                                            Account Status
                                        </div>
                                        <div class="card-block bg-white">
                                            <div class="form-group row pb-2">
                                                <label class="col-lg-5 col-form-label">THEY OWE</label>
                                                <div class="col-lg-7 account-status-left-column">
                                                    <span>{{customer.accountStatus.theyOwe | formatMoney}}</span>
                                                </div>
                                            </div>

                                            <div class="form-group row pb-2">
                                                <label class="col-lg-5 col-form-label">OVERDUE</label>
                                                <div class="col-lg-7 account-status-left-column">
                                                    <span style="color: red; font-weight: bold">{{customer.accountStatus.overdue | formatMoney}}</span>
                                                </div>
                                            </div>

                                            <div class="form-group row pb-2">
                                                <label class="col-lg-5 col-form-label">Revenue Total Current FY</label>
                                                <div class="col-lg-7 account-status-left-column">
                                                    <span style="font-weight: bold">{{customer.accountStatus.revenueCurrent | formatMoney}}</span>
                                                </div>
                                            </div>

                                            <div class="form-group row pb-2">
                                                <label class="col-lg-5 col-form-label">Revenue Total Past FY's</label>
                                                <div class="col-lg-7 account-status-left-column">
                                                    <span style="font-weight: bold">{{customer.accountStatus.revenuePast | formatMoney}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <amazon-ads></amazon-ads>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab name="Cars" id="cars">
                      <div class="row">
                        <div>
                          <div class="bg-white pagination-block-bottom">
                            <b-table
                                class="rs-scroll rs-scroll--y"
                                id="customer-cars-table"
                                responsive
                                hover
                                :items="customer.vehicles"
                                :per-page="carsPerPage"
                                :current-page="carsCurrentPage"
                                :fields="carsFields"
                            >
                              <template v-slot:cell(action)="row">
                                <a v-if="!isCustomerUser" href="#" @click="deleteCar(row.item.id)"> <i class="bx bx-trash"></i></a>
                              </template>
                            </b-table>

                          </div>
                          <block-pagination
                              :role="'Cars'"
                              :count="customer.vehicles ? customer.vehicles.length : 0"
                              :countFiltered="customer.vehicles ? customer.vehicles.length : 0"
                              :currentPage="carsCurrentPage"
                              @changedCurrentPage="value => carsCurrentPage = value"
                              :perPage="carsPerPage"
                              @changedPerPage="value => carsPerPage = value"
                          >
                          </block-pagination>
                        </div>
                      </div>
                    </tab>
                    <template   slot="nav-item-right">
                        <button v-if="isStatusActive" @click="saveData" type="button" class="btn btn-primary w-min-sm waves-effect waves-light">Save</button>
                    </template>
                </tabs>
            </div>
        </div>
    </div>
</template>

<style>
    .card-header {
        border-bottom: 1px solid #FFFFFF !important;
    }

    .edit-customer .bx-trash {
        color: #FF5E5D;
        font-size: 21px;
    }

    .account-status-left-column {
        margin-top: 7px;
    }
</style>
<style scoped>
    .V3 label.radio > input[type="radio"]:checked:disabled + span.icon i {
      color: white;
    }

    .V3 label.radio > input[type="radio"]:checked + span.icon {
      background-color: #5E79FF;
    }

    @media screen and (max-width: 767px) {
        .pr-0 {
            padding-left: 0;
        }

        .pl-0 {
            padding-right: 0;
        }
    }
</style>
